<template>
    <div class="container">
        <crud ref="crud" :options="options">
            <template slot="header">
                <el-form-item>
                    <el-button @click="doGenerate" v-if="$perm('develop_generator_create')" type="success">生成代码
                    </el-button>
                </el-form-item>
            </template>
        </crud>
    </div>
</template>

<script>
export default {
    name: "List",
    data: function () {
        return {
            options: {
                addBtn: true,
                editBtn: true,
                delBtn: true,
                pager: true,
                addUrl: 'develop.generator.add',
                addPerm: 'develop_generator_add',
                editUrl: 'develop.generator.edit',
                editPerm: 'develop_generator_edit',
                delUrl: 'develop.generator.delete',
                delPerm: 'develop_generator_delete',
                listUrl: 'develop.generator.page',
                formLabelWidth: '100px',
                columns: [
                    {prop: "module", label: "模块", required: true},
                    {prop: "table_name", label: "表名", required: true},
                    {prop: "table_prefix", label: "表前缀", required: true},
                    {prop: "path", label: "保存路径", required: true},
                    {prop: "remark", label: "备注",}
                ],
            }
        }
    },
    methods: {
        doGenerate: function () {
            const rows = this.$refs.crud.getSelectedRows()
            if (rows.length <= 0) return this.$message.warning("请选择需要生成的记录~")
            this.$helper._post(this, 'develop.generator.generate', {ids: rows.map(item => item.id)}, resp => {
                this.$message.success(resp.message)
            })
        }
    }
}
</script>

<style scoped>

</style>
